/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

import {appRoutes} from '../../../../../app/routing/routes'
import {UserRole, useAuth, userRoleObj} from '../../../../../app/modules/auth'
import {surveyTypeObj} from '../../../../../app/Utils/constants'
const {CompanyAdmin, LocationHead, User, Supervisor, Superadmin, DatesmetronAnalyst} = userRoleObj

export const companyRouteState = {
  company: `all`,
}

export const datesMetronRouteState = {
  company: `${CompanyAdmin}`,
  datesMetron: `${DatesmetronAnalyst}-${Supervisor}`,
}

export const volumetricAnalysisRouteState = {
  allSurveys: surveyTypeObj.all,
  rejectedSurveys: surveyTypeObj.rejected,
}

const isUserRoleMatched = (userRoleArray: UserRole[], userRole: UserRole) => {
  return userRoleArray.some((role) => role === userRole)
}
const defaultUsersArray = [LocationHead as UserRole, CompanyAdmin as UserRole]

const routeAccessibilityObj = {
  [appRoutes.datesMetronCompany]: [Superadmin as UserRole],
  [appRoutes.datesMetronAnalystSupervisor]: [Superadmin as UserRole],
  [appRoutes.users]: [...defaultUsersArray, Superadmin as UserRole],
  [appRoutes.location]: [...defaultUsersArray],
  [appRoutes.department]: [...defaultUsersArray],
  [appRoutes.stockyards]: [...defaultUsersArray],
  [appRoutes.bulkMaterial]: [...defaultUsersArray, User as UserRole],
  [appRoutes.surveys]: [
    ...defaultUsersArray,
    User as UserRole,
    DatesmetronAnalyst as UserRole,
    Supervisor as UserRole,
  ],
  [appRoutes.addSurvey]: [...defaultUsersArray, User as UserRole, DatesmetronAnalyst as UserRole],
  [appRoutes.pileAssignment]: [
    Superadmin as UserRole,
    DatesmetronAnalyst as UserRole,
    Supervisor as UserRole,
  ],
  [appRoutes.pileVolumetricAnalysisRoute]: [DatesmetronAnalyst as UserRole],
  [appRoutes.supervisorDashboard]: [Supervisor as UserRole],
}

const getRoutesAccessibility = (userRole: UserRole, route: string) =>
  isUserRoleMatched(routeAccessibilityObj[route], userRole)

const SidebarMenuMain = () => {
  const intl = useIntl()

  const {userRole} = useAuth()
  const checkUserMatch = (currentUserRole: string) => currentUserRole === userRole
  const isCompanyRouteNotAccessible = [User, DatesmetronAnalyst, Supervisor, Superadmin].some(
    checkUserMatch
  )
  const isMaterialDashboardRouteNotAccessible = [Superadmin].some(checkUserMatch)
  const isDatesMetronRouteAccessible = [Superadmin].some(checkUserMatch)
  const companySideBarHeadingText = 'Company'
  const datesMetronSideBarHeadingText = 'DatesMetron'

  const datesMetronSideBarRoutes = [
    {
      route: appRoutes.datesMetronCompany,
      title: 'Company',
      isVisible: getRoutesAccessibility(userRole, appRoutes.datesMetronCompany),
      icon: 'bi-person',
      params: datesMetronRouteState.company,
      isParams: true,
    },
    {
      route: appRoutes.datesMetronAnalystSupervisor,
      title: 'Analyist & Supervisor',
      isVisible: getRoutesAccessibility(userRole, appRoutes.datesMetronAnalystSupervisor),
      icon: 'bi bi-people-fill',
      params: datesMetronRouteState.datesMetron,
      isParams: true,
    },
  ]

  const companySideBarRoutes = [
    {
      route: appRoutes.users,
      title: 'Users',
      isVisible: getRoutesAccessibility(userRole, appRoutes.users),
      icon: 'bi-person',
      isParams: true,
      params: companyRouteState.company,
    },
    {
      route: appRoutes.location,
      title: 'Locations',
      isVisible: getRoutesAccessibility(userRole, appRoutes.location),
      icon: 'bi-geo-alt',
    },
    {
      route: appRoutes.department,
      title: 'Departments',
      isVisible: getRoutesAccessibility(userRole, appRoutes.department),
      icon: 'bi-people-fill',
    },
    {
      route: appRoutes.stockyards,
      title: 'Stockyards',
      isVisible: getRoutesAccessibility(userRole, appRoutes.stockyards),
      icon: 'bi-geo-fill',
    },
  ]

  const materialDashboardSideBarRoutes = [
    {
      route: appRoutes.bulkMaterial,
      title: 'Bulk Material',
      isVisible: getRoutesAccessibility(userRole, appRoutes.bulkMaterial),
      icon: 'bi-minecart-loaded',
    },
    {
      route: appRoutes.surveys,
      title: 'Surveys',
      isVisible: getRoutesAccessibility(userRole, appRoutes.surveys),
      icon: 'bi-clipboard2-data',
    },
    {
      route: appRoutes.addSurvey,
      title: 'Add Survey',
      isVisible: getRoutesAccessibility(userRole, appRoutes.addSurvey),
      icon: 'bi-clipboard-plus',
    },
    {
      route: appRoutes.pileAssignment,
      title: userRole === DatesmetronAnalyst ? 'Pile Assignment' : 'Pile Approval',
      isVisible: getRoutesAccessibility(userRole, appRoutes.pileAssignment),
      icon: 'bi-card-checklist',
    },
    {
      route: appRoutes.pileVolumetricAnalysisRoute,
      title: 'Volumetric Analysis',
      isVisible: getRoutesAccessibility(userRole, appRoutes.pileVolumetricAnalysisRoute),
      icon: 'bi-diagram-3-fill',
      isParams: true,
      params: volumetricAnalysisRouteState.allSurveys,
    },
    {
      route: appRoutes.supervisorDashboard,
      title: 'Supervisor Dashboard',
      isVisible: getRoutesAccessibility(userRole, appRoutes.supervisorDashboard),
      icon: 'bi-exclude',
    },
  ]

  const reportsSideBarRoutes = [
    {route: appRoutes.departmentWiseReport, title: 'Departmentwise Report', isVisible: true},
    {route: appRoutes.stockyardWiseReport, title: 'Stockyardwise Report', isVisible: true},
    {route: appRoutes.locationWithDateWiseReport, title: 'Locationwise Report', isVisible: true},
    {route: appRoutes.biScreen, title: 'BI Screen', isVisible: true},

    // {
    //   route: appRoutes.reconcilliation,
    //   title: 'Reconcilliation',
    //   isVisible: isRouteVisibleForUser(),
    // },
    // {route: appRoutes.timelineReport, title: 'Timeline Report', isVisible: isRouteVisibleForUser()},
    // {route: appRoutes.pilesReports, title: 'Piles Report', isVisible: isRouteVisibleForUser()},
    // {route: appRoutes.ticketReport, title: 'Ticket Report', isVisible: isRouteVisibleForUser()},
  ]

  const realTimeMeasurementsSideBarRoutes = [
    {
      route: appRoutes.lidarMeasurement,
      title: 'Lidar',
      isVisible: true,
      icon: 'bi-align-center',
    },
    {
      route: appRoutes.cctvCameraMeasurement,
      title: 'CCTV Camera',
      isVisible: true,
      icon: 'bi-webcam-fill',
    },
    {
      route: appRoutes.selfMeasurement,
      title: 'Self Measurements',
      isVisible: true,
      icon: 'bi-activity',
    },
  ]

  const analysisSideBarRoutes = [
    {
      route: appRoutes.stockyardAnalysis,
      title: 'Stockyard Analysis',
      isVisible: true,
      icon: 'bi-graph-down',
    },
    {
      route: appRoutes.fragmentationAnalysis,
      title: 'Fragmentation Analysis',
      isVisible: true,
      icon: 'bi-bar-chart-steps',
    },
  ]

  return (
    <>
      <SidebarMenuItem
        to={`/${appRoutes.dashboard}`}
        icon={'bi-grid-fill'}
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {isDatesMetronRouteAccessible && (
        <SidebarMenuItemWithSub
          to={appRoutes.datesMetron}
          title={datesMetronSideBarHeadingText}
          fontIcon='bi-archive'
          icon={'bi-person-workspace'}
        >
          {datesMetronSideBarRoutes
            .filter(({isVisible}) => isVisible)
            ?.map((sideBarElement) => (
              <SidebarMenuItem
                to={sideBarElement.route}
                title={sideBarElement.title}
                hasBullet={false}
                key={sideBarElement.route}
                icon={sideBarElement.icon}
                params={sideBarElement.params}
                isParams={sideBarElement.isParams}
              />
            ))}
        </SidebarMenuItemWithSub>
      )}
      {!isCompanyRouteNotAccessible && (
        <SidebarMenuItemWithSub
          to={appRoutes.company}
          title={companySideBarHeadingText}
          fontIcon='bi-archive'
          icon={'bi-person-workspace'}
        >
          {companySideBarRoutes
            ?.filter((route) => route.isVisible)
            ?.map((sideBarElement) => (
              <SidebarMenuItem
                to={sideBarElement.route}
                title={sideBarElement.title}
                hasBullet={false}
                key={sideBarElement.route}
                icon={sideBarElement.icon}
                isParams={sideBarElement?.isParams || false}
                params={sideBarElement?.params || ''}
              />
            ))}
        </SidebarMenuItemWithSub>
      )}
      {!isMaterialDashboardRouteNotAccessible && (
        <SidebarMenuItemWithSub
          to={appRoutes.materialDashboard}
          title='Material Dashboard'
          fontIcon='bi-archive'
          icon={'bi-gear-fill'}
        >
          {materialDashboardSideBarRoutes
            ?.filter((route) => route.isVisible)
            ?.map((sideBarElement) => (
              <SidebarMenuItem
                to={sideBarElement.route}
                title={sideBarElement.title}
                hasBullet={false}
                key={sideBarElement.route}
                icon={sideBarElement.icon}
                isParams={sideBarElement?.isParams || false}
                params={sideBarElement?.params || ''}
              />
            ))}
        </SidebarMenuItemWithSub>
      )}
      <SidebarMenuItemWithSub
        to={appRoutes.reports}
        title='Reports'
        fontIcon='bi-archive'
        icon={'bi-journal-text'}
      >
        {reportsSideBarRoutes
          ?.filter((route) => route.isVisible)
          ?.map((sideBarElement) => (
            <SidebarMenuItem
              to={sideBarElement.route}
              title={sideBarElement.title}
              hasBullet={true}
              key={sideBarElement.route}
            />
          ))}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to={`${appRoutes.realTimeMeasurements}`}
        title='Real Time Measurements'
        fontIcon='bi-archive'
        icon={'bi-file-earmark-ruled-fill'}
      >
        {realTimeMeasurementsSideBarRoutes
          ?.filter((route) => route.isVisible)
          ?.map((sideBarElement) => (
            <SidebarMenuItem
              to={sideBarElement.route}
              title={sideBarElement.title}
              hasBullet={false}
              key={sideBarElement.route}
              icon={sideBarElement.icon}
            />
          ))}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to={`${appRoutes.analysis}`}
        title='Analysis'
        fontIcon='bi-archive'
        icon={'bi-graph-up'}
      >
        {analysisSideBarRoutes
          ?.filter((route) => route.isVisible)
          ?.map((sideBarElement) => (
            <SidebarMenuItem
              to={sideBarElement.route}
              title={sideBarElement.title}
              hasBullet={false}
              key={sideBarElement.route}
              icon={sideBarElement.icon}
            />
          ))}
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
