export const primaryColor = '#febd16'
export const whiteColor = '#fff'
export const redColor = '#F64E60'
export const blackColor = '#333'
export const lightGrayColor = '#d3d3d3'
export const GCP_BASE_URL = process.env.REACT_APP_GCP_FILE_BASE_URL
export const GCP_BUCKET = process.env.REACT_APP_GCP_BUCKET

export const fileUplaodStatus = {
  UploadToGCPFromFronEnd: 'UploadedFromFrontend',
  DownloadedToServer: 'DownloadedToServer',
  Processing: 'Processing',
  Processed: 'Processed',
  UploadedToCloudAfterProcessing: 'UploadedToCloudAfterProcessing',
  DeletedFromServerAfterProcessing: 'DeletedFromServerAfterProcessing',
  downloading: 'Downloading',
  Errored: 'Errored',
}

export type CompanyRoles = 'CompanyAdmin' | 'User' | 'LocationHead' | 'Auditor'
export type DatesmetronRoles = 'Superadmin' | 'DatesmetronAnalyst' | 'Supervisor' | 'Surveyor'
export type companyOrDatesmetronRole = CompanyRoles | DatesmetronRoles

export const fileTypeObj = {
  Ortho: 'Ortho',
  Pointcloud: 'Pointcloud',
  Shape: 'Shape',
  Snap: 'Snap',
  Images: 'Images',
}
export const surveyType = 'surveyType'
export const surveyTypeObj = {
  all: 'all',
  rejected: 'rejected',
}
export type StockyardType = 'Indoor' | 'Outdoor'
