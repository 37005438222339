//supervisor

import {Column} from '../../../../components/AppDataTable'
import {OptionObjInterface} from '../../../../components/AppSelect'
import {IFileData} from './apiModel'

//supervisor
//pile assessment count
export interface IPileCountList {
  id: string
  name: string
  pending: number
  completed: number
  total: number
}
export interface IPileCountColumn extends Column {
  field: keyof IPileCountList
}

//pile approval
export type publishingStatusType = 'inProgress' | 'notPublished' | 'approved' | 'rejected'
export const pilePublishingStatus: Record<publishingStatusType, publishingStatusType> = {
  inProgress: 'inProgress',
  notPublished: 'notPublished',
  approved: 'approved',
  rejected: 'rejected',
}

export interface IPileApprovalList {
  id: string
  volume: number
  volumetricCompetition: string
  files: IFileData[]
  publishingStatus: publishingStatusType
  isApproved: boolean
  locationId: string
  pileCode: string
  isEdited: boolean
}
export interface IPileApprovalColumn extends Column {
  field: keyof IPileApprovalList
}
export const pileApprovalColumn: IPileApprovalColumn[] = [
  {field: 'volume', headerText: 'Volume', width: '200'},
]
export interface IPileCountDataFromServer extends IPileCountList {}

//server apis data
export interface IPileApprovalDataFromServer extends IPileApprovalList {}
export interface IPileCountListDataFromServer extends IPileCountList {}

export const surveyTypeObj = {
  all: 'all',
  analyst: 'analyst',
  approved: 'approved',
}

export const surveyTypeArray: OptionObjInterface[] = [
  {label: 'All Surveys', value: surveyTypeObj.all},
  {label: 'Selected Analyst Survey', value: surveyTypeObj.analyst},
  {label: 'Approved Surveys', value: 'all'},
]
