import {createContext, ReactNode, useContext, useReducer, useRef} from 'react'

import {AppToast} from '../Utils/AppToast'
import {useFileUpload} from '../hooks/useFileUpload'
import {ISurveyPhotoUpload} from '../modules/MaterialDashboard/model'
import {fileUplaodStatus} from '../Utils/constants'
import {uploadSurveyFile} from '../modules/MaterialDashboard/service'
import {uploadFiles} from '../../config/aws-client-config'
import {uploadFileToGCP} from '../modules/MaterialDashboard/PileManagement/service'

interface IFileUploaderProviderContext {
  children: ReactNode
}

export interface IHandleAfterUpload {
  fileName: string
  fileType: string
  fileExtension: string
  selectedSurveyDate: Date
  selectedSurveyId: string
}

export interface IFileUploadToAWS extends IHandleAfterUpload {
  file: File
}

export interface IFileUploadToGCP extends IHandleAfterUpload {
  file: File
}

interface IFileUploadContext {
  fileCount: number
  noOfFilesUploading: number
  noOfFilesUploaded: number
  isFileUploading: boolean
  totalFileProgress: number
  fileProgress: number[]
  file: File | null
  fileExtension: string
  fileType: string
  loading: boolean
  isUploadBtnDisabled: boolean
  resetState: () => void
  canFileBeUploaded: () => boolean
  handleFileTypeChange: (fileType: string) => void
  handleFileChange: (file: File) => void
  handleSuccessfulFileUpload: (data: IHandleAfterUpload) => void
  uploadToAWS: (data: IFileUploadToAWS) => void
  uploadToGCP: (data: IFileUploadToGCP) => void
  setFileCount: (fileCount: number) => void
}

const initialState: IFileUploadContext = {
  fileCount: 0,
  noOfFilesUploading: 0,
  noOfFilesUploaded: 0,
  isFileUploading: false,
  totalFileProgress: 0,
  fileProgress: [],
  file: null,
  fileExtension: '',
  fileType: '',
  loading: false,
  isUploadBtnDisabled: false,
  canFileBeUploaded: () => false,
  handleFileChange: (file: File) => {},
  handleFileTypeChange: (fileType: string) => {},
  resetState: () => {},
  handleSuccessfulFileUpload: (data: IHandleAfterUpload) => {},
  uploadToAWS: (data: any) => {},
  uploadToGCP: (data: any) => {},
  setFileCount: (fileCount: number) => {},
}

interface IFileUploadingInitialState
  extends Pick<
    IFileUploadContext,
    | 'fileProgress'
    | 'noOfFilesUploading'
    | 'noOfFilesUploaded'
    | 'totalFileProgress'
    | 'isFileUploading'
  > {}

const fileUploadingInitialState: IFileUploadingInitialState = {
  fileProgress: [],
  noOfFilesUploading: 0,
  totalFileProgress: 0,
  noOfFilesUploaded: 0,
  isFileUploading: false,
}

const FileUploadContext = createContext<IFileUploadContext>(initialState)

export const useFileUploadContext = () => useContext(FileUploadContext)

const FileUploadContextProvider = ({children}: IFileUploaderProviderContext) => {
  const {toast} = AppToast
  const fileCountRef = useRef(0)
  const fileCount = fileCountRef?.current ? fileCountRef.current : 0

  const [state, setState] = useReducer(
    (
      state: IFileUploadingInitialState,

      fields:
        | Partial<IFileUploadingInitialState>
        | {fileProgressNumber: number}
        | {filesInNumber: number}
    ) => {
      if ('fileProgressNumber' in fields)
        return {
          ...state,
          fileProgress: [...state.fileProgress, fields.fileProgressNumber],
        }
      if ('filesInNumber' in fields) {
        return {
          ...state,
          noOfFilesUploading: state.noOfFilesUploading + fields.filesInNumber,
        }
      }
      return {...state, ...fields}
    },
    fileUploadingInitialState
  )
  const {fileProgress, noOfFilesUploading, noOfFilesUploaded, totalFileProgress, isFileUploading} =
    state
  const {
    fileUploadState,
    isUploadBtnDisabled,
    handleFileChange,
    resetState,
    canFileBeUploaded,
    handleFileTypeChange,
    resetLoading,
    setLoading,
  } = useFileUpload()
  const {file, fileExtension, fileType, loading} = fileUploadState

  const setFileCount = (fileCount: number) => {
    if (fileCountRef.current < 0) {
      fileCountRef.current = 0
      return
    }
    fileCountRef.current = fileCountRef.current + fileCount
  }

  const handleSuccessfulFileUpload = async ({
    fileName,
    fileType,
    selectedSurveyDate,
    selectedSurveyId,
    fileExtension,
  }: IHandleAfterUpload) => {
    try {
      setLoading()
      const requestObj: ISurveyPhotoUpload = {
        date: new Date(selectedSurveyDate),
        fileName,
        fileType,
        status: fileUplaodStatus.UploadToGCPFromFronEnd,
        fileExtension,
      }
      const response = await uploadSurveyFile(selectedSurveyId, requestObj)
      if (response) {
        setState({filesInNumber: -1})
        resetState()
        if (fileCountRef?.current === 1) toast.success('File uploaded successfully')
        setFileCount(-1)
      }
    } catch (error) {
      console.error('upload to server after aws file upload', error)
    } finally {
      resetLoading()
    }
  }

  const uploadToAWS = async ({
    fileName,
    file,
    fileType,
    fileExtension,
    selectedSurveyDate,
    selectedSurveyId,
  }: IFileUploadToAWS) => {
    try {
      setLoading()
      setState({filesInNumber: 1})
      const changeProgress = (progress: number) => {
        setState({fileProgressNumber: progress})
      }
      const data = await uploadFiles(fileName, file)
      await handleSuccessfulFileUpload({
        fileName,
        fileType,
        selectedSurveyDate,
        selectedSurveyId,
        fileExtension,
      })
    } catch (error: any) {
      console.error('file context file upload error', error)
    } finally {
      resetLoading()
      setState({isFileUploading: false})
    }
  }
  const uploadToGCP = async ({
    fileName,
    file,
    fileType,
    fileExtension,
    selectedSurveyDate,
    selectedSurveyId,
  }: IFileUploadToGCP) => {
    try {
      setLoading()
      setState({filesInNumber: 1})
      const {data} = await uploadFileToGCP(selectedSurveyId, file)
      if (data.publicUrl)
        await handleSuccessfulFileUpload({
          fileName,
          fileType,
          selectedSurveyDate,
          selectedSurveyId,
          fileExtension,
        })
    } catch (error: any) {
      console.error('file context file upload error', error)
      setState({filesInNumber: -1})
      resetState()
      toast.error('File not uploaded, Something went wrong')
    } finally {
      resetLoading()
      setState({isFileUploading: false})
    }
  }
  return (
    <FileUploadContext.Provider
      value={{
        loading,
        isUploadBtnDisabled,
        noOfFilesUploading,
        noOfFilesUploaded,
        isFileUploading,
        totalFileProgress,
        file,
        fileExtension,
        fileProgress,
        fileType,
        fileCount,
        canFileBeUploaded,
        handleSuccessfulFileUpload,
        handleFileChange,
        handleFileTypeChange,
        resetState,
        uploadToAWS,
        uploadToGCP,
        setFileCount,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  )
}
export default FileUploadContextProvider
